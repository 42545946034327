import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { Play } from './Icons';

const EmbedCover = ({ cover, embed, onClick, canPlay,  ...others }) => {
    const [isPlaying, setPlaying] = useState(false);

    useEffect(() => {
        if (canPlay === false) {
            setPlaying(false);
        }
    }, [canPlay]);

    const handlePlay = event => {
        setPlaying(prev => !prev);
        if (onClick instanceof Function) {
            onClick(event);
        }
    };

    return (
        <Module {...others} onClick={handlePlay}>
            {cover && cover.local && <Image fluid={cover.local.sharp.image} alt={cover.alt} />}
            <Play />
            {isPlaying && (
                <iframe
                    src={embed}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            )}
        </Module>
    );
};

const Module = styled.div`
    position: relative;
    cursor: pointer;
    background: ${props => props.theme.colors.black};

    &:before {
        content: '';
        display: block;
        padding-bottom: ${(1 / (16 / 9)) * 100}%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: ${props => props.theme.colors.black};
    }

    ${Play} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        color: ${props => props.theme.colors.white};
    }
`;

const Image = styled(GatsbyImage)`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export default EmbedCover;
