import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { linkReset } from '../styles/others';
import PathologyPreview, { StyledPathologyPreview } from './PathologyPreview';
import Row from './Row';
import useMedia, { mqMobile } from '../hooks/useMedia';
import { ArrowDown } from './Icons';
import * as Scroll from 'react-scroll';
import { transitionLinkColor } from './LinkHover';

const StyledBlock = styled(Row)`
    display: flex;
    align-items: stretch;
    overflow: initial;
`;

const StyledScroller = styled.div`
    width: ${(20 / 62) * 100}%;
    box-shadow: inset -1px 0 0 0 ${props => props.theme.colors.grey};

    ${media.mobile`
        width: 100%;
    `}

    ${media.mobile`
        box-shadow: none;
    `}
`;

const StyledPathologies = styled.div`
    width: ${(42 / 62) * 100}%;
    position: sticky;
    align-self: flex-start;
    top: ${props => props.theme.sizes.headerHeight}px;

    ${StyledPathologyPreview} {
        position: absolute;
        top: 0;
        left: 0;

        &:first-child {
            position: relative;
        }
    }

    ${media.mobile`
        top: ${props => props.theme.sizes.headerHeightMobile}px;
    `}
`;

const StyledItem = styled.div`
    position: relative;
    display: block;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: ${sizeAsBase(2)};
        right: ${sizeAsBase(2)};
        height: 1px;
        background: ${props => props.theme.colors.grey};
    }

    &:last-child:after {
        display: none;
    }

    ${media.mobile`
        &:after {
            left: 0;
            right: 0;
        }
    `}

    ${media.large`
        &:after {
            left: ${sizeAsMax(2)};
            right: ${sizeAsMax(2)};
        }
    `}
`;

const StyledItemAction = styled.a`
    ${linkReset}
    ${transitionLinkColor}
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: ${props => props.theme.spacing.s120};
    text-align: left;
    padding: ${props => props.theme.spacing.s20} ${sizeAsBase(2)};

    span {
        margin-left: ${props => props.theme.spacing.s20};
    }

    i {
        position: absolute;
        font-style: normal;
        color: ${props => props.theme.colors.red};
        bottom: ${props => props.theme.spacing.s20};
        right: ${sizeAsBase(2)};
        opacity: ${props => (props.isActive ? 1 : 0)};
        transition: opacity 0.2s ${props => props.theme.easings.easeInOutSine};
    }

    ${ArrowDown} {
        position: absolute;
        top: ${props => props.theme.spacing.s30};
        right: ${props => props.theme.spacing.s30};
        font-size: 9px;
        pointer-events: none;
        transform: rotate(${props => (props.isActive ? 180 : 0)}deg);
    }

    @media (hover: hover) {
        &:hover {
            color: ${props => props.theme.colors.red};
        }
    }

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};

        i {
            right: ${sizeAsMax(2)};
        }
    `}

    ${media.mobile`
        padding-left: ${sizeAsBase(4)};
        padding-right: ${props => props.theme.spacing.s60};
        display: block;

        span {
            margin-left: 0;
        }
    `}
`;

const Pathologies = ({ pathologies, selectedUid = false }) => {
    const [activeId, setActiveId] = useState(pathologies[0].uid);
    const isMobile = useMedia(mqMobile, [true], false);
    const $element = useRef(null);

    useEffect(() => {
        if (selectedUid) {
            setActiveId(selectedUid);
        }
    }, [selectedUid]);

    const pathologiesDOM = pathologies.map(({ data, uid, fields }) => (
        <PathologyPreview
            key={`pathology-${uid}`}
            uid={uid}
            video={data.video}
            isActive={uid === activeId}
            description={data.description}
            title={data.title.text}
            content={data.content.html}
            url={fields.url}
        />
    ));

    const scrollerItems = pathologies.map(({ data, uid }, index) => {
        const { title, accronym } = data;
        return (
            <StyledItem key={`scroll-item-${uid}`} href={`#${uid}`} isActive={uid === activeId}>
                <StyledItemAction
                    isActive={uid === activeId}
                    onClick={event => {
                        event.preventDefault();
                        setActiveId(uid);
                    }}
                >
                    <h3>{title.text}</h3>
                    <span>({accronym})</span>
                    {isMobile ? <ArrowDown /> : <i>—›</i>}
                </StyledItemAction>
                {isMobile && pathologiesDOM[index]}
            </StyledItem>
        );
    });

    return (
        <Scroll.Element name="pathologies" ref={$element}>
            <StyledBlock>
                <StyledScroller>{scrollerItems}</StyledScroller>
                {!isMobile && <StyledPathologies>{pathologiesDOM}</StyledPathologies>}
            </StyledBlock>
        </Scroll.Element>
    );
};

export default Pathologies;
