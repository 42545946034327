import React, { forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import beziers from '../core/beziers';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { buttonReset } from '../styles/others';
import { Close } from './Icons';
import { h2 } from '../styles/typography';
import Rte from './Rte';
import BorderSeparator from './BorderSeparator';

const SideModal = ({ title, html, handleClose, children }, ref) => {
    let startX = 0;
    let startY = 0;

    const handleTouch = event => {
        const t = event.changedTouches ? event.changedTouches[0] : event.targetTouches[0];

        switch (event.type) {
            case 'touchstart':
                startX = t.pageX;
                startY = t.pageY;
                break;
            case 'touchend':
                if (t.pageX - startX > 50 && Math.abs(t.pageY - startY) < 30) {
                    handleClose();
                }
                break;
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.documentElement.classList.add('no-scroll');
            window.addEventListener('touchstart', handleTouch, { passive: true });
            window.addEventListener('touchend', handleTouch, { passive: true });
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('touchstart', handleTouch, { passive: true });
                window.removeEventListener('touchend', handleTouch, { passive: true });
            }
        };
    }, []);

    return (
        <StyledModal ref={ref}>
            <StyledOverlay onClick={handleClose}>&nbsp;</StyledOverlay>
            <StyledModalWrapper>
                <StyledClose onClick={handleClose} title="Fermer" ariaLabel="Fermer">
                    <Close />
                </StyledClose>
                <StyledTitle>{title}</StyledTitle>
                <BorderSeparator />
                {html && <StyledRte dangerouslySetInnerHTML={{ __html: html }} />}
                {children}
            </StyledModalWrapper>
        </StyledModal>
    );
};

export default posed(forwardRef(SideModal))({});

const StyledModal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${props => props.theme.zIndex.modal};
    overflow: hidden;
`;

const PosedOverlay = posed.div({
    enter: {
        opacity: 1,
        transition: {
            ease: beziers.easeOutQuad,
            duration: 400
        }
    },
    exit: {
        opacity: 0,
        transition: {
            ease: beziers.easeOutQuad,
            duration: 300,
            delay: 200
        }
    }
});

const StyledOverlay = styled(PosedOverlay)`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0;
    cursor: pointer;
`;

// Wrapper
const PosedWrapper = posed.div({
    enter: {
        x: '0%',
        transition: {
            ease: beziers.easeOutExpo,
            duration: 800,
            delay: 350
        }
    },
    exit: {
        x: '100%',
        transition: {
            ease: beziers.easeOutQuad,
            duration: 300,
            delay: 100
        }
    }
});

const StyledModalWrapper = styled(PosedWrapper)`
    background: ${props => props.theme.colors.greyLight};
    width: ${sizeAsBase(45)};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    ${media.large`
        width: calc((100vw - 1550px) / 2 + ${sizeAsMax(45)});
        padding-right: calc((100vw - 1550px) / 2 + ${sizeAsMax(3)});
    `}

    ${media.mobile`
        width: calc(100vw - 40px);
    `}
`;

const StyledClose = styled.button`
    ${buttonReset};
    position: absolute;
    top: ${props => props.theme.spacing.s20};
    right: ${sizeAsBase(2)};
    color: ${props => props.theme.colors.font};

    ${Close} {
        font-size: 22px;
    }

    ${media.large`
        right: calc((100vw - 1550px) / 2 + ${sizeAsMax(5)});
    `}
`;

const StyledTitle = styled.h2`
    ${h2}
    padding-top: ${props => props.theme.spacing.s80};
    padding-bottom: ${props => props.theme.spacing.s80};
    padding-left: ${sizeAsBase(10)};
    padding-right: ${sizeAsBase(2)};
    box-shadow: inset -1px 0 0 0 ${props => props.theme.colors.border};

    ${media.large`
        padding-left: ${sizeAsMax(10)};
        padding-right: ${sizeAsMax(2)};
    `}
`;
const StyledRte = styled(Rte)`
    padding-top: ${props => props.theme.spacing.s60};
    padding-bottom: ${props => props.theme.spacing.s60};
    padding-left: ${sizeAsBase(10)};
    padding-right: ${sizeAsBase(2)};
    box-shadow: inset -1px 0 0 0 ${props => props.theme.colors.border};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-left: ${sizeAsBase(-8)};
    }

    ${media.large`
        padding-left: ${sizeAsMax(10)};
        padding-right: ${sizeAsMax(2)};

        h1, h2, h3, h4, h5, h6 {
            margin-left: ${sizeAsMax(-8)};
        }
    `}
`;
