import React, { useState } from 'react';
import styled from 'styled-components';
import * as Scroll from 'react-scroll';
import Row from './Row';
import { linkReset } from '../styles/others';
import { sizeAsBase } from '../styles/utils';
import { ArrowDown } from './Icons';
import EmbedCover from './EmbedCover';

const StyledItem = styled.div`
    box-shadow: inset 0 -1px 0 0 ${props => props.theme.colors.border};
`;

const StyledAction = styled.a`
    ${linkReset}
    display: block;
    cursor: pointer;
    position: relative;
    width: 100%;
    min-height: ${props => props.theme.spacing.s120};
    text-align: left;
    padding: ${props => props.theme.spacing.s20} ${sizeAsBase(4)};
    padding-right: ${props => props.theme.spacing.s60};

    ${ArrowDown} {
        position: absolute;
        top: ${props => props.theme.spacing.s30};
        right: ${props => props.theme.spacing.s30};
        font-size: 9px;
        pointer-events: none;
        transform: rotate(${props => (props.isActive ? 180 : 0)}deg);
    }
`;

const StyledContent = styled.div`
    padding: 0 ${sizeAsBase(2)} ${props => props.theme.spacing.s40};
    display: ${props => (props.isActive ? 'block' : 'none')};

    div + div {
        margin-top: ${sizeAsBase(2)};
    }
`;

const DiagnosticTestsContentMobile = ({ items }) => {
    const [activeId, setActiveId] = useState(null);

    return (
        <Row>
            {items.map(({ data, uid }) => (
                <Scroll.Element key={`diagnostic-${uid}`} name={`diagnostic-${uid}`}>
                    <StyledItem>
                        <StyledAction
                            isActive={uid === activeId}
                            onClick={event => {
                                event.preventDefault();
                                setActiveId(activeId === uid ? null : uid);
                            }}
                        >
                            <h3>{data.title.text}</h3>
                            <ArrowDown />
                        </StyledAction>
                        <StyledContent isActive={uid === activeId}>
                            <div dangerouslySetInnerHTML={{ __html: data.content.html }} />
                            {data.video && data.video.document.length > 0 && (
                                <EmbedCover {...data.video.document[0].data} />
                            )}
                        </StyledContent>
                    </StyledItem>
                </Scroll.Element>
            ))}
        </Row>
    );
};

export default DiagnosticTestsContentMobile;
