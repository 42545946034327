import React from 'react';
import styled, { css } from 'styled-components';
import Link from './Link';
import { media } from '../styles';
import { linkReset } from '../styles/others';

const DefaultCSS = css`
    ${linkReset}
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    padding: ${props => props.theme.spacing.s10} ${props => props.theme.spacing.s20};
    font-size: ${props => props.theme.fontSize.small};
    line-height: ${props => props.theme.lineHeight.small};
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-decoration: none;
    transition: color 0.6s ${props => props.theme.easings.easeOutQuart};


    & > span {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 100%;
        background: ${props => props.theme.colors.red};
        z-index: 1;
        transform-origin: 0% 50%;
        transition: transform 0.6s ${props => props.theme.easings.easeOutQuart};
    }

    ${media.mobile`
        font-size: ${props => props.theme.fontSize.xsmall};
        line-height: ${props => props.theme.lineHeight.xsmall};
    `}
`;

const StyledRedBorder = styled(Link)`
    ${DefaultCSS};
    color: ${props => props.theme.colors.red};
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.red};

    &:before {
        transform: scaleX(0);
    }

    &:hover {
        color: ${props => props.theme.colors.white};

        &:before {
            transform: scaleX(1);
        }
    }
`;

const LinkButton = ({ children, ...others }) => (
    <StyledRedBorder {...others}>
        <span>{children}</span>
    </StyledRedBorder>
);

export default LinkButton;

const StyledRedPlain = styled(Link)`
    ${DefaultCSS};
    color: ${props => props.theme.colors.white};
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.red};

    &:before {
        transform-origin: 100% 50%;
        transform: scaleX(1);
    }

    &:hover {
        color: ${props => props.theme.colors.red};

        &:before {
            transform: scaleX(0);
        }
    }
`;

export const LinkButtonPlain = ({ children, ...others }) => (
    <StyledRedPlain {...others}>
        <span>{children}</span>
    </StyledRedPlain>
);
