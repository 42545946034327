import React from 'react';
import styled from 'styled-components';
import Row from './Row';
import { panelTitle } from '../styles/typography';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';

const StyledTitle = styled.h2`
    ${panelTitle};
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)};

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        padding-top: ${props => props.theme.spacing.s20};
        padding-bottom: ${props => props.theme.spacing.s15};
    `}
`;

const RowTitled = ({ title, children, ...others }) => (
    <Row {...others}>
        <StyledTitle>{title}</StyledTitle>
        {children}
    </Row>
);

export default RowTitled;
