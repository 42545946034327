import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import * as Scroll from 'react-scroll';
import CoverImageText from '../components/CoverImageText';
import RowTitled from '../components/RowTitled';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { h3, h2 } from '../styles/typography';
import Rte from '../components/Rte';
import BorderSeparator from '../components/BorderSeparator';
import Pathologies from '../components/Pathologies';
import DiagnosticTests from '../components/DiagnosticTests';
import { Helmet } from 'react-helmet';
import { useAppear } from '../hooks/useAppear';

const Rythmology = ({ data, location }) => {
    const {
        seo_title,
        seo_description,
        cover_text,
        cover_images,
        rythmology_title,
        rythmology_description,
        rythmology_content,
        pathologies_title,
        pathologies_introduction,
        tests_title,
        tests_cover,
        tests_description,
        tests_links,
        telecardiology_title,
        telecardiology_description,
        telecardiology_content
    } = data.page.data;

    const { state } = location;

    // const filteredPathologies = data.pathologies.edges
    const filteredPathologies = data.menu.data.pathologies
        .filter(({ link }) => link !== null)
        .map(({ link }) => link.document[0]);

    // Hooks
    const refAppearRythmoLeft = useAppear();
    const refAppearRythmoRight = useAppear(0.2);
    const refAppearPathologies = useAppear();
    const refAppearTeleLeft = useAppear();
    const refAppearTeleRight = useAppear(0.2);

    return (
        <Page>
            <Helmet>
                <title>{seo_title}</title>
                {seo_description && <meta name="description" content={seo_description} />}
            </Helmet>
            <CoverImageText text={cover_text.html} images={cover_images} color="white" />
            <BorderSeparator />
            <RowTitled title={rythmology_title}>
                <StyledIntroAndText>
                    <div
                        ref={refAppearRythmoLeft}
                        dangerouslySetInnerHTML={{ __html: rythmology_description.html }}
                    />
                    <Rte
                        ref={refAppearRythmoRight}
                        dangerouslySetInnerHTML={{ __html: rythmology_content.html }}
                    />
                </StyledIntroAndText>
            </RowTitled>
            <BorderSeparator />
            <RowTitled title={pathologies_title}>
                <StyledPathologies
                    ref={refAppearPathologies}
                    dangerouslySetInnerHTML={{ __html: pathologies_introduction.html }}
                />
            </RowTitled>
            <BorderSeparator />
            <Pathologies
                pathologies={filteredPathologies}
                selectedUid={state ? state.selectedPathologyUid : false}
            />
            <BorderSeparator color="grey" />
            <DiagnosticTests
                title={tests_title}
                image={tests_cover.local.sharp.image}
                description={tests_description.html}
                links={tests_links}
                selectedUid={state ? state.selectedDiagnosticUid : false}
            />
            <Scroll.Element name="telecardiolgy">
                {telecardiology_title &&
                    telecardiology_description.html &&
                    telecardiology_content.html && (
                        <RowTitled title={telecardiology_title}>
                            <StyledIntroAndText>
                                <div
                                    ref={refAppearTeleLeft}
                                    dangerouslySetInnerHTML={{
                                        __html: telecardiology_description.html
                                    }}
                                />
                                <Rte
                                    ref={refAppearTeleRight}
                                    dangerouslySetInnerHTML={{
                                        __html: telecardiology_content.html
                                    }}
                                />
                            </StyledIntroAndText>
                        </RowTitled>
                    )}
            </Scroll.Element>
        </Page>
    );
};

export default Rythmology;

const Page = styled.div`
    position: relative;
`;

const StyledIntroAndText = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.s20} ${sizeAsBase(2)}
        ${props => props.theme.spacing.s100};

    & > div:first-child {
        ${h3};
        color: ${props => props.theme.colors.red};
        width: ${sizeAsBase(23)};
    }

    & > div:last-child {
        width: ${sizeAsBase(28)};
    }

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};

        & > div:first-child {
            width: ${sizeAsMax(23)};
        }

        & > div:last-child {
            width: ${sizeAsMax(28)};
        }
    `}

    ${media.mobile`
        padding-top: 0;
        flex-direction: column;
        & > div:first-child {
            width: 100%;
            margin-bottom: ${props => props.theme.spacing.s20};
        }
        & > div:last-child {
            width: 100%;
        }
    `}
`;

const StyledPathologies = styled.div`
    ${h2};
    padding: ${props => props.theme.spacing.s10} ${sizeAsBase(7)}
        ${props => props.theme.spacing.s120};

    ${media.large`
        padding-left: ${sizeAsMax(7)};
        padding-right: ${sizeAsMax(7)};
    `}

    ${media.mobile`
        padding-bottom: ${props => props.theme.spacing.s80};
    `}
`;

export const pageQuery = graphql`
    query RythmologyQuery($lang: String!) {
        page: prismicPageRythmology(lang: { eq: $lang }) {
            data {
                seo_title
                seo_description
                cover_text {
                    html
                }
                cover_images {
                    image {
                        local: localFile {
                            ...CoverImage
                        }
                    }
                }
                rythmology_title
                rythmology_description {
                    html
                }
                rythmology_content {
                    html
                }
                pathologies_title
                pathologies_introduction {
                    html
                }
                tests_title
                tests_cover {
                    local: localFile {
                        ...sharp700x394
                    }
                }
                tests_description {
                    html
                }
                tests_links {
                    linked_test {
                        document {
                            ... on PrismicDiagnosticTest {
                                uid
                                data {
                                    title {
                                        text
                                    }
                                    content {
                                        html
                                    }
                                    video {
                                        document {
                                            ... on PrismicVideo {
                                                uid
                                                data {
                                                    embed
                                                    cover {
                                                        local: localFile {
                                                            ...sharp1050x600
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                telecardiology_title
                telecardiology_description {
                    html
                }
                telecardiology_content {
                    html
                }
            }
        }
        menu: prismicMenu(lang: { eq: $lang }) {
            data {
                pathologies: rythmology_submenu_pathologies {
                    link: pathology_link {
                        document {
                            ... on PrismicPathology {
                                uid
                                fields {
                                    url
                                }
                                data {
                                    title {
                                        text
                                    }
                                    accronym: pathology_accronym
                                    description {
                                        html
                                    }
                                    content {
                                        html
                                    }
                                    video {
                                        document {
                                            ... on PrismicVideo {
                                                uid
                                                data {
                                                    embed
                                                    cover {
                                                        local: localFile {
                                                            ...sharp1050x600
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
