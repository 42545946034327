import React from 'react';
import styled from 'styled-components';
import * as Scroll from 'react-scroll';
import GatsbyImage from 'gatsby-image';
import Row from './Row';
import { panelTitle, h3 } from '../styles/typography';
import BorderSeparator from './BorderSeparator';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import DiagnosticTestsContentDesktop from './DiagnosticTestsContentDesktop';
import DiagnosticTestsContentMobile from './DiagnosticTestsContentMobile';
import useMedia, { mqMobile } from '../hooks/useMedia';
import { useAppear } from '../hooks/useAppear';

const StyledBlock = styled(Row)`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)};

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        padding-top: ${props => props.theme.spacing.s20};
        padding-bottom: ${props => props.theme.spacing.s40};
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
    `}
`;

const StyledImage = styled(GatsbyImage)`
    min-width: ${sizeAsBase(28)};
    flex-basis: ${sizeAsBase(28)};
    margin-right: ${sizeAsBase(2)};

    ${media.large`
        min-width: ${sizeAsMax(28)};
        flex-basis: ${sizeAsMax(28)};
        margin-right: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        margin-right: 0;
        margin-top: ${props => props.theme.spacing.s20};
        min-width: 100%;
        flex-basis: 100%;
        width: 100%;
    `}
`;

const StyledDescription = styled.div`
    ${h3};
    color: ${props => props.theme.colors.red};
`;

const StyledTitle = styled.h2`
    ${panelTitle};
    margin-bottom: ${props => props.theme.spacing.s40};

    ${media.mobile`
        margin-bottom: ${props => props.theme.spacing.s10};
    `}
`;

const DiagnosticTests = ({ title, image, description, links }) => {
    const linksMap = links
        .filter(({ linked_test }) => linked_test !== null)
        .map(({ linked_test }) => linked_test.document[0]);

    const isMobile = useMedia(mqMobile, [true], false);

    // Hooks
    const refAppear = useAppear();

    return (
        <Scroll.Element name="diagnostics">
            <StyledBlock>
                <StyledImage fluid={image} />
                <div>
                    <StyledTitle>{title}</StyledTitle>
                    <StyledDescription
                        ref={refAppear}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
            </StyledBlock>
            <BorderSeparator />
            {isMobile ? (
                <DiagnosticTestsContentMobile items={linksMap} />
            ) : (
                <DiagnosticTestsContentDesktop items={linksMap} />
            )}
        </Scroll.Element>
    );
};

export default DiagnosticTests;
