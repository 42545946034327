import React from 'react';
import styled from 'styled-components';
import { sizeAsBase } from '../styles/utils';
import { LinkButtonPlain } from './LinkButton';
import { withModal } from '../store/ModalProvider';
import SideModal from './SideModal';
import EmbedCover from './EmbedCover';
import { media } from '../styles';

export const StyledPathologyPreview = styled.div`
    width: 100%;
    background: ${props => props.theme.colors.white};
    box-shadow: 0 1px 0 0 ${props => props.theme.colors.grey};
    visibility: ${props => (props.isActive ? 'visible' : 'hidden')};

    ${media.mobile`
        padding: 0 ${sizeAsBase(2)} ${props => props.theme.spacing.s40};

        visibility: visible;
        display: ${props => (props.isActive ? 'block' : 'none')};
        height: ${props => (props.isActive ? 'auto' : 0)};
        box-shadow: none;
    `}
`;

const StyledPathologyPreviewText = styled.div`
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.s30} ${sizeAsBase(2)};

    a,
    button {
        margin-left: ${props => props.theme.spacing.s30};
        white-space: nowrap;
    }

    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.xsmall};

    ${media.mobile`
        height: auto;
        padding: ${props => props.theme.spacing.s20} 0;

        a, button {
            display: none;
        }
    `}
`;

class PathologyPreview extends React.Component {
    constructor() {
        super(...arguments);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleModalPoseComplete = this.handleModalPoseComplete.bind(this);
    }

    handleModalPoseComplete() {
        const { hasModal, uid } = this.props;
        if (hasModal(`modal-${uid}`) !== true) {
            document.documentElement.classList.remove('no-scroll');
        }
    }

    handleCloseModal() {
        const { removeModal, uid } = this.props;
        removeModal(`modal-${uid}`);
    }

    handleOpenModal(event) {
        event.preventDefault();
        const { addModal, uid, content, title } = this.props;
        const modal = (
            <SideModal
                key={`modal-${uid}`}
                handleClose={this.handleCloseModal}
                onPoseComplete={this.handleModalPoseComplete}
                title={title}
                html={content}
            />
        );

        addModal(modal);
    }

    render() {
        const { video, description, url, className, isActive } = this.props;
        const { cover, embed } = video.document[0].data;

        return (
            <StyledPathologyPreview className={className} isActive={isActive}>
                <EmbedCover cover={cover} embed={embed} canPlay={isActive} />
                <StyledPathologyPreviewText>
                    <div dangerouslySetInnerHTML={{ __html: description.html }} />
                    <LinkButtonPlain to={url} onClick={this.handleOpenModal}>
                        Voir la fiche
                    </LinkButtonPlain>
                </StyledPathologyPreviewText>
            </StyledPathologyPreview>
        );
    }
}

export default withModal(PathologyPreview);
