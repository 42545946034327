import React from 'react';
import styled from 'styled-components';
import * as Scroll from 'react-scroll';
import Row from './Row';
import BorderSeparator from './BorderSeparator';
import EmbedCover from './EmbedCover';
import { media, theme } from '../styles';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { h2 } from '../styles/typography';
import { transitionLinkColor } from './LinkHover';
import { ANCHOR_EASE } from './LinkAnchor';
import { useAppear } from '../hooks/useAppear';

const DiagnosticTestsContentDesktop = ({ items }) => {
    return (
        <>
            <StyledSticky>
                <StyledStickyLinks>
                    {items.map(({ data, uid }) => (
                        <Scroll.Link
                            key={uid}
                            activeClass={'is-active'}
                            to={`diagnostic-${uid}`}
                            spy={true}
                            smooth={ANCHOR_EASE}
                            duration={350}
                            offset={-theme.sizes.headerHeight - 110}
                        >
                            {data.title.text}
                        </Scroll.Link>
                    ))}
                </StyledStickyLinks>
                <BorderSeparator />
            </StyledSticky>
            {items.map(({ data, uid }) => {
                const refAppearLeft = useAppear();
                const refAppearRight = useAppear(0.2);
                return (
                    <Scroll.Element key={`diagnostic-${uid}`} name={`diagnostic-${uid}`}>
                        <StyledTest>
                            <h3 ref={refAppearLeft}>{data.title.text}</h3>
                            <div ref={refAppearRight}>
                                <div dangerouslySetInnerHTML={{ __html: data.content.html }} />
                                {data.video && data.video.document.length > 0 && (
                                    <EmbedCover {...data.video.document[0].data} />
                                )}
                            </div>
                        </StyledTest>
                        <BorderSeparator />
                    </Scroll.Element>
                );
            })}
        </>
    );
};

export default DiagnosticTestsContentDesktop;

const StyledSticky = styled.div`
    position: sticky;
    top: ${props => props.theme.sizes.headerHeight}px;
    background: ${props => props.theme.colors.white};
    z-index: 2;

    ${media.mobile`
        top: ${props => props.theme.sizes.headerHeightMobile}px;
    `}
`;

const StyledStickyLinks = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 110px;
    padding: 0 ${sizeAsBase(2)};

    a {
        cursor: pointer;
        ${transitionLinkColor};

        &.is-active,
        &:hover {
            color: ${props => props.theme.colors.red};
        }
    }

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}
`;

const StyledTest = styled(Row)`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.s60} ${sizeAsBase(2)};

    h3 {
        ${h2};
    }

    & > div:last-child {
        width: ${sizeAsBase(28)};
        margin-left: ${sizeAsBase(2)};

        div + div {
            margin-top: ${sizeAsBase(2)};
        }
    }

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};

        & > div:last-child {
            width: ${sizeAsMax(28)};
            margin-left: ${sizeAsMax(2)};
        }
    `}
`;
